<template>
  <div>
    <v-container>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            PERSONAL
          </h3>
        </v-card-title>
      </v-card>
      <br>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
            PERSONAL
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            color="success"
            dark
            elevation="0"
          >{{ formTitle }}
          </v-toolbar>
          <br>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.person.dni"
                    label="DNI"
                    outlined
                    dense
                    :counter="8"
                    :error-messages="errors.get('dni')"
                    @keydown="errors.clear('dni')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.person.firstName"
                    label="Nombres"
                    outlined
                    dense
                    :error-messages="errors.get('firstName')"
                    @keydown="errors.clear('firstName')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.person.lastName"
                    label="Apellidos"
                    outlined
                    dense
                    :error-messages="errors.get('lastName')"
                    @keydown="errors.clear('lastName')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.person.phone"
                    label="Celular"
                    outlined
                    dense
                    :error-messages="errors.get('phone')"
                    @keydown="errors.clear('phone')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.person.password"
                    label="Contraseña"
                    outlined
                    dense
                    type="password"
                    :error-messages="errors.get('password')"
                    @keydown="errors.clear('password')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="editedItem.person.direction"
                    label="Dirección"
                    outlined
                    dense
                    :error-messages="errors.get('direction')"
                    @keydown="errors.clear('direction')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-autocomplete
                    v-model="editedItem.area.entity.id"
                    :items="entities"
                    label="Entidad"
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.get('entity_id')"
                    @input="errors.clear('entity_id'); listAreas(editedItem.area.entity.id)"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-autocomplete
                    v-model="editedItem.area.id"
                    :items="areas"
                    label="Area"
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.get('area_id')"
                    @input="errors.clear('area_id')"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="editedItem.role.id"
                    :items="roles"
                    label="Rol"
                    outlined
                    dense
                    clearable
                    item-text="description"
                    item-value="id"
                    :error-messages="errors.get('role_id')"
                    @input="errors.clear('role_id')"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="editedItem.state.id"
                    :items="states"
                    label="Estado"
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.get('id')"
                    @input="errors.clear('id')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success darken-1"
              text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success darken-1"
              text
              @click="save"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-text-field
            v-model="search"
            clearable
            outlined
            dense
            clear-icon="mdi-delete"
            label="Buscar"
            class="mt-7"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-autocomplete
            v-model="filterAssignment"
            :items="entities"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
            label="Filtrar por endidad"
            class="mt-7"
            @input="fetchFiltersAssignments(filterAssignment)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="assignments"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
        :server-items-length="totalAssignments"
        :options.sync="options"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-if="progress" v-slot:no-data>
          <div class="text-center">
            <v-progress-circular
              :size="40"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
      <div class="text-center ma-2">
        <v-snackbar
            v-model="snackbar"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </div>
</template>

<script>
import {Errors} from "../../plugins/errors";
import axios from "axios";
import debounce from "lodash/debounce";

export default {
  name: "Assignment",
  components: {},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      progress: false,
      assignments: [],
      entities: [],
      areas: [],
      filterAssignment: null,
      listFilterAssignment: [],
      roles: [],
      states: [],
      errors: new Errors(),
      snackbar: false,
      filters: {
        search: ''
      },
      text: 'Operación realizada con éxito !!!',
      totalAssignments: 0,
      options: {},
      headers: [
        {text: 'SUCURSAL',            value: 'area.entity.name', sortable: false, class: "success white--text"},
        {text: 'AREA',                value: 'area.name',        sortable: false, class: "success white--text"},
        {text: 'ROL',                 value: 'role.description', sortable: false, class: "success white--text"},
        {text: 'DNI',                 value: 'person.dni',       sortable: false, class: "success white--text"},
        {text: 'APELLIDOS Y NOMBRES', value: 'person.full_name', sortable: false, class: "success white--text"},
        {text: 'DIRECCION',           value: 'person.direction', sortable: false, class: "success white--text"},
        {text: 'CELULAR',             value: 'person.phone',     sortable: false, class: "success white--text"},
        {text: 'ESTADO',              value: 'state.name',       sortable: false, class: "success white--text"},
        {text: 'ACCIONES',            value: 'actions',          sortable: false, class: "success white--text"},
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        person: {
          id: null,
          dni: '',
          firstName: '',
          lastName: '',
          direction: '',
          phone: '',
          password: ''
        },
        area: {
          id: null,
          name: '',
          entity: {
            id: null,
            name: ''
          }
        },
        role: {
          id: null,
          description: ''
        },
        state: {
          id: null,
          name: ''
        }
      },
      defaultItem: {
        person: {
          id: null,
          dni: '',
          full_name: '',
          direction: '',
          phone: ''
        },
        area: {
          id: null,
          name: '',
          entity: {
            id: null,
            name: ''
          }
        },
        role: {
          id: null,
          description: ''
        },
        state: {
          id: null,
          name: ''
        }
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'REGISTRAR PERSONAL' : 'EDITAR PERSONAL'
    },
    search: {
      get() {
        return this.filters.search
      },
      set(value) {
        this.filters.search = value
        this.filterSearch()
      }
    },
    dni() {
      return this.editedItem.person.dni
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      async handler() {
        await this.fetchAssignments();
      }
    },
    dni(search) {
      if (search.length === 8) {
        axios.get(`/api/assignments/filter/person?search=${search}`)
          .then(response => {
            if (response.data.data.length) {
              this.editedItem.person = response.data.data['0']
            }
          })
          .catch(error => console.log(error))
      }
    }
  },
  created() {
    this.fetchAssignments()
    //this.listModifyAssignments()
  },
  methods: {
    fetchAssignments() {
      const params = {...this.options, ...this.filters}
      this.progress = true
      axios.get('/api/assignments', {params})
        .then(response => {
          this.assignments = response.data.data.data
          this.totalAssignments = response.data.data.total
          this.entities = response.data.entities
          this.listFilterAssignment = response.data.areas
          this.roles = response.data.roles
          this.states = response.data.states
          this.progress = false
        })
    },

    listAreas(id) {
      axios.get(`/api/assignments/areas/${id}`)
        .then(response => {
          this.areas = response.data.areas
        })
    },

    saveAssignments() {
      const createAssignment = {
        'dni': this.editedItem.person.dni,
        'firstName': this.editedItem.person.firstName,
        'lastName': this.editedItem.person.lastName,
        'direction': this.editedItem.person.direction,
        'password': this.editedItem.person.password,
        'phone': this.editedItem.person.phone,
        'id': this.editedItem.person.id,
        'entity_id': this.editedItem.area.entity.id,
        'area_id': this.editedItem.area.id,
        'role_id': this.editedItem.role.id,
        'assignment_state_id': this.editedItem.state.id
      }

      axios.post('/api/assignments', createAssignment)
        .then(() => {
          this.fetchAssignments()
          this.close()
          this.snackbar = true
          this.reset()
        }, error => this.errors.record(error.response.data.errors));
    },

    updateAssignments() {
      const updateAssignment = {
        'dni': this.editedItem.person.dni,
        'firstName': this.editedItem.person.firstName,
        'lastName': this.editedItem.person.lastName,
        'direction': this.editedItem.person.direction,
        'password': this.editedItem.person.password,
        'phone': this.editedItem.person.phone,
        'id': this.editedItem.person.id,
        'person_id': this.editedItem.person.id,
        'entity_id': this.editedItem.area.entity.id,
        'area_id': this.editedItem.area.id,
        'role_id': this.editedItem.role.id,
        'assignment_state_id': this.editedItem.state.id
      }

      axios.put(`/api/assignments/${this.editedItem.id}`, updateAssignment)
        .then(() => {
          this.fetchAssignments()
          this.close()
          this.snackbar = true;
        }, error => this.errors.record(error.response.data.errors));
    },

    filterSearch: debounce(async function () {
      await this.fetchAssignments();
    }, 1000),

    fetchFiltersAssignments() {
      if (this.filterAssignment === null) {
        this.fetchAssignments()
      } else {
        axios.get(`/api/assignments/filter/${this.filterAssignment}`)
          .then(response => {
            this.assignments = response.data.data.data
            this.totalAssignments = response.data.data.total
          })
      }
    },

    reset() {
      this.defaultItem = {
        person: {
          id: null,
          dni: '',
          full_name: '',
          direction: '',
          phone: ''
        },
        area: {
          id: null,
          name: '',
          entity: {
            id: null,
            name: ''
          }
        },
        role: {
          id: null,
          description: ''
        },
        state: {
          id: null,
          name: ''
        }
      }
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateAssignments()
      } else {
        this.saveAssignments()
      }
      this.errors.record('');
    },

    deleteAssignments() {
      axios.delete(`/api/assignments/${this.editedItem.id}`)
        .then(() => {
          this.fetchAssignments()
          this.close()
          this.snackbar = true;
        })
    },

    editItem(item) {
      this.listAreas(0);
      this.editedIndex = this.assignments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.assignments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteAssignments()
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.errors.record('')
      this.areas = []
      this.reset()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },

}
</script>
<style>
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-input {
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}

.v-progress-circular {
  margin: 1rem;
}
</style>
